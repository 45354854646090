@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap");

.mainpage {
  min-height: 84vh;
  display: flex;
  padding: 10px 0;
}

.page {
  width: 100%;
  margin-top: 5px;
}

.heading {
  font-size: 40px;
  font-family: "Montserrat";
  padding: 5px 10px;
}

@media only screen and (max-width: 600px) {
  .heading {
    font-size: 30px;
    display: flex;
    justify-content: center;
  }
}
